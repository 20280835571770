import { IAddress } from "./Iaddress";
import { IProductInsight } from "./Iproduct";
import { IUser } from "./Iuser";

export interface IShop {
  id?: string;
  name?: string;
  theme?: string;
  merchantId?: string;
  description?: string;
  walletAddress?: string;
  phone?: string;
  whatsappPhoneNumber?: string;
  email?: string;
  address?: IAddress;
  slug?: string;
  verified?: boolean;
  coverPicture?: string;
  logoUrl?: string;
  bannerUrl?: string;
  user?: IUser;
  rating?: number;
  profilePicture?: string;
  headerColorHex?: string;
  footerColorHex?: string;
  primaryColorHex?: string;
  secondaryColorHex?: string;
  socialMedialLinks?: {
    id?: string;
    facebook?: string;
    twitter?: string;
    instagram?: string;
    youtube?: string;
    createdAt?: string;
    updatedAt?: string;
  };

  // Feature Flags for Shop
  isShopLimited?: boolean;
  isOrderFormEnabled?: boolean;

  createdAt?: string;
  updatedAt?: string;
}

export enum IShopPermissionStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export interface IPermission {
  // View Permissions
  canViewCustomers?: boolean;
  canViewOrders?: boolean;
  canViewProducts?: boolean;
  canViewShop?: boolean;
  canViewDiscounts?: boolean;
  canViewShopAnalytics?: boolean;
  canViewShopSettings?: boolean;
  canViewShopWallet?: boolean;
  canViewStaff?: boolean;

  // Edit Permissions
  canEditStaff?: boolean;
  canEditCustomers?: boolean;
  canEditDiscounts?: boolean;
  canEditOrders?: boolean;
  canEditProducts?: boolean;
  canEditShop?: boolean;
  canDeleteShop?: boolean;
  canEditShopSettings?: boolean;
  canEditShopWallet?: boolean;
}

export enum IRole {
  STAFF = "staff",
  OWNER = "owner",
  ADMIN = "admin",
  DEVELOPER = "developer",
  MANAGER = "manager",
}

export interface IShopPermission {
  id?: string;
  userID?: string;
  role?: IRole;
  shopId?: string;
  shopName?: string;
  owenerUserID?: string;
  userFullName?: string;
  userProfileImage?: string;
  shopProfileImage?: string;
  status?: IShopPermissionStatus;
  permissions?: IPermission;
}

export enum IShopPermissionIndex {
  BY_USER_ID = "byUserId",
  BY_SHOP_ID = "byShopId",
  BY_SHOP_AND_USER = "byShopAndUser",
}

export enum IShopTemplate {
  PGECOM = "pg-storefront",
}

export interface IShopInsight {
  product: IProductInsight;
  shopId: string;
  platform: string;
  updatedAt: string;
  createdAt: string;
  customer: {
    topCustomers: { id: string; name: string }[];
    totalCustomers: number;
    totalSpent: number;
    customerOrderFrequency: {
      "0": number;
    };
  };
  totalProducts: number;
  id: string;
  totalOrders: number;
  totalSales: number;
}
