// Migrate all the transaction types from the backend to Crisp
// Update the status to DONE when the migration is complete

export enum TransactionType {
  ALL = "all",
  RESET_PIN = "reset_pin",

  // Deposit
  PAYPAL = "paypal",
  REFUND = "refund",
  OFFLINE_DEPOSIT = "offline_line",
  REFUND_TRANSACTION = "refund_transaction",
  VENMO_DEPOSIT = "venmo_deposit",

  // Credit
  CREDIT_DEPOSIT = "credit_deposit",
  CREDIT_TRANSFER_BALANCE = "credit_transfer_balance",

  GOURDES_TO_USD = "gourdes_to_usd",
  WISE_DEPOSIT = "wise_deposit",
  PAYONEER_DEPOSIT = "payoneer_deposit",
  WAVE_PAYMENT_DEPOSIT = "wave_payment_deposit",

  // Commision
  PHYSICALCARD_COMMISSION = "physicalcard_commission",
  GIFT_CARD_COMMISION = "giftcard_commission",

  // Deposit
  ZELLE_DEPOSIT = "zelle_deposit",
  CHASE_DEPOSIT = "chase_deposit",
  SOGEBANK_DEPOSIT = "sogebank_deposit",
  UNIBANK_DEPOSIT = "unibank_deposit",
  BANK_RESERVAS_DEPOSIT = "bank_reservas_deposit",
  BANK_BHD_LEON_DEPOSIT = "bank_bhd_leon_deposit",
  BANK_OF_AMERICA_DEPOSIT = "bank_of_america_deposit",
  LENDING_DEPOSIT = "lending_deposit",
  BUH_DEPOSIT = "buh_deposit",
  CRYPTO_DEPOSIT = "crypto_deposit",
  FLUTTERWAVE_DEPOSIT = "flutterwave_deposit",
  STRIPE_DEPOSIT = "stripe_deposit",
  STRIPE = "stripe",
  TOPUP_DEPOSIT = "topup_deposit",
  REFERRAL_DEPOSIT = "referral_deposit",
  MASTERCARD_DEPOSIT = "mastercard_deposit",
  MASTERCARD_DEPOSIT_CREDIT = "mastercard_deposit_credit",
  VISA_DEPOSIT = "visa_deposit",
  VISA_WITHDRAW = "visa_withdraw",
  SUDO_MASTERCARD_WITHDRAW = "sudo_mastercard_withdraw",
  SUDO_VISA_WITHDRAW = "sudo_visa_withdraw",
  CASHAPP_DEPOSIT = "cashapp_deposit",
  PAYPAL_DEPOSIT = "paypal_deposit",
  TRANSFER_RECEIVED = "received_transfer",
  LOAN_PAYMENT = "loan_payment",
  LOAN_PAYMENT_DEPOSIT = "loan_payment_deposit",

  // Investment
  INVESTMENT = "investment",
  INVESTMENT_DEPOSIT = "investment_deposit",
  INVESTMENT_WITHDRAW = "investment_withdraw",
  INVESTMENT_REPAYMENT = "investment_repayment",

  TRANSFERPAM_RECEIVED = "transferpam_received",
  MONCASH_DEPOSIT = "moncash_deposit",
  MONCASH_DEPOSIT_VIA_API = "moncash_deposit_via_api",
  NATCASH_DEPOSIT = "natcash_deposit",
  COINBASE_DEPOSIT = "coinbase_deposit",
  SALES = "sales",
  PREPAID_VISA_CARD = "prepaid_visa_card",
  BINANCE_DEPOSIT = "binance_deposit",
  BINANCE_WITHDRAW = "binance_withdraw",
  BITCOIN_DEPOSIT = "BITCOIN_DEPOSIT",
  BANK_DEPOSIT = "bank_deposit",
  CARD_BALANCE_RECHARGE = "card_balance_recharge",
  MANUAL_BALANCE_RECHARGE_INCREASE = "manual_balance_recharge_increase",
  GIFT_CARD_DEPOSIT = "giftcard_deposit",

  // Domain Name
  DOMAIN_PURCHASE = "domain_purchase",

  // Withdraw
  VIRTUALCARD = "virtualcard",
  VIRTUAL_CARD_FUNDS_RELOAD = "virtual_card_funds_reload",
  CREDIT_WITHDRAW = "credit_withdraw",
  VIRTUAL_MASTERCARD = "virtual_mastercard",
  PHYSICALCARD = "physicalcard",
  DISPUTE = "dispute",
  BANK = "bank",
  GIFT_CARD = "giftcard",
  MANUAL_BALANCE_RECHARGE_DECREASE = "manual_balance_recharge_decrease",
  TOPUP = "topup",
  MONCASH = "moncash",
  LENDING_WITHDRAW = "lending_withdraw",
  BANK_WITHDRAW = "bank_withdraw",
  PAYPAL_WITHDRAW = "paypal_withdraw",
  CASHAPP_WITHDRAW = "cashapp_withdraw",
  ZELLE_WITHDRAW = "zelle_withdraw",
  BANK_RESERVAS_WITHDRAW = "bank_reservas_withdraw",
  BANK_BHD_LEON_WITHDRAW = "bank_bhd_leon_withdraw",

  NATCASH = "natcash",
  WITHDRAW = "withdraw",
  MONCASH_WITHDRAW = "moncash_withdraw",
  NATCASH_WITHDRAW = "natcash_withdraw",
  CRYPTO = "crypto",
  PLAN = "plan",
  TRANSFER = "transfer",
  TRANSFERPAM = "transferpam",
  PAYME = "payme",
  VERIFICATION = "verification",
  PAYPAL_PAYOUT = "paypal_payout",
  PG_PAY = "pgpay",
  PG_PAY_DEPOSIT = "pgpay_deposit",
  CARD_TRANSACTION = "cardtransaction",
  CARD_APPROVAL = "cardapproval",

  // Transfer
  WESTERN_UNION = "western_union",

  // Identity Verification
  REGISTRATION_FEE = "registration_fee",
  IDENTITY_VERIFICATION = "identity_verification",
  DOCUMENT_VERIFICATION = "document_verification",
  AML_VERIFICATION = "aml_verification",

  // Bank
  SOGEBANK_WITHDRAW = "sogebank_withdraw",
  MERCURY_WITHDRAW = "mercury_withdraw",
  PAYONEER_WITHDRAW = "payoneer_withdraw",
  BANK_OF_AMERICA_WITHDRAAW = "bank_of_america_withdraw",
  WISE_WITHDRAW = "wise_withdraw",
  BUH_WITHDRAW = "buh_withdraw",
  UNIBANK_WITHDRAW = "unibank_withdraw",
  CAPITAL_BANK = "capital_bank_withdraw",
  BNC_WITHDRAW = "bnc_withdraw",

  // Courses
  COURSE_PURCHASE = "course_purchase",

  // Ecommerce Purchase
  AMAZON_PURCHASE = "amazon_purchase",
  SHEIN_PURCHASE = "shein_purchase",
  UBER_RIDE = "uber_ride",
  UBER_EATS_PURCHASE = "uber_eats_purchase",
  ALIBABA_PURCHASE = "alibaba_purchase",
  TEMU_PURCHASE = "temu_purchase",
  ALIEXPRESS_PURCHASE = "aliexpress_purchase",

  CHECKOUT = "checkout",

  // User
  USER_ACTIVATED = "user_activated",
  USER_DISABLED_MFA = "user_disabled_mfa",
  PHYSICAL_CARD_UPDATED = "physical_card_updated",
  CARD_CONNECTED = "card_connected",
  INTEGRATION = "integration",
  VISA_WITHDRAW_DECLINED = "visa_withdraw_declined",
  REFUND_RECEIVED = "refund_received",
  SHOP_INVITATION_REJECTED = "shop_invitation_rejected",
  SHOP_INVITATION_ACCEPTED = "shop_invitation_accepted",

  SHOP_CREATED = "shop_created",
  SHOP_INVITE = "SHOP_INVITE",

  USER_CHANGE_EMAIL = "user_change_email",
  INTEGRATION_FEE = "integration_fee",
}

export interface ITPPCardTransaction {
  txId: { type: string; id: string }; // Recently CHANGED
  transactionId: { type: string; id: string };
  transactionAmount: { currency: string; amount: string };
  balanceAfter: { currency: string; amount: string };
  cardholderFee: { currency: string; amount: string };
  processedTimestamp: any;
  additionalFields: {
    merchantName: string;
    merchantCategoryCode: string;
    merchantId: string;
    merchantTerminalCountry: string;
    forexPaddingCurrency: string;
    forexPaddingAmount: string;
    forexFeeCurrency: string;
    forexFeeAmount: string;
    authorisationCode: string;
    authorisationState: string;
    declineReason: string;
  };
  actualBalanceAfter: { currency: string; amount: string };
  actualBalanceAdjustment: { currency: string; amount: string };
  availableBalanceAfter: { currency: string; amount: string };
  availableBalanceAdjustment: { currency: string; amount: string };
  statementEntryState: string;
  entryState: string;
  transactionState: string;
}

export const transactionTypeDomains: { [key: string]: string } = {
  [TransactionType.CREDIT_DEPOSIT]: "pgecom.com",
  [TransactionType.REFUND]: "pgecom.com",
  [TransactionType.CREDIT_TRANSFER_BALANCE]: "pgecom.com",
  [TransactionType.LENDING_DEPOSIT]: "pgecom.com",
  [TransactionType.TRANSFER]: "pgecom.com",
  [TransactionType.TRANSFER_RECEIVED]: "pgecom.com",
  [TransactionType.VIRTUALCARD]: "pgecom.com",
  [TransactionType.PREPAID_VISA_CARD]: "pgecom.com",
  [TransactionType.WITHDRAW]: "pgecom.com",
  [TransactionType.CREDIT_WITHDRAW]: "pgecom.com",
  [TransactionType.VIRTUAL_MASTERCARD]: "pgecom.com",
  [TransactionType.PHYSICALCARD]: "pgecom.com",
  [TransactionType.GIFT_CARD]: "pgecom.com",
  [TransactionType.PG_PAY]: "pgecom.com",
  [TransactionType.PG_PAY_DEPOSIT]: "pgecom.com",
  [TransactionType.REGISTRATION_FEE]: "pgecom.com",

  [TransactionType.LOAN_PAYMENT]: "pgecom.com",
  [TransactionType.LOAN_PAYMENT_DEPOSIT]: "pgecom.com",

  // Visa
  [TransactionType.VISA_DEPOSIT]: "visa.com",
  [TransactionType.VISA_WITHDRAW]: "visa.com",
  [TransactionType.SUDO_VISA_WITHDRAW]: "visa.com",

  // Ding Top Up
  [TransactionType.TOPUP_DEPOSIT]: "ding.com",
  [TransactionType.TOPUP]: "ding.com",

  // Bank of America
  [TransactionType.BANK_OF_AMERICA_DEPOSIT]: "www.bankofamerica.com",
  [TransactionType.BANK_OF_AMERICA_WITHDRAAW]: "www.bankofamerica.com",

  // Comply Cube
  [TransactionType.DOCUMENT_VERIFICATION]: "complycube.com",
  [TransactionType.VERIFICATION]: "complycube.com",

  // Wave Payment
  [TransactionType.WAVE_PAYMENT_DEPOSIT]: "waveapps.com",

  // Wise
  [TransactionType.WISE_DEPOSIT]: "wise.com",
  [TransactionType.WISE_WITHDRAW]: "wise.com",

  // Zelle
  [TransactionType.ZELLE_DEPOSIT]: "zellepay.com",
  [TransactionType.ZELLE_WITHDRAW]: "zellepay.com",

  // Unibank
  [TransactionType.UNIBANK_DEPOSIT]: "unibankhaiti.com",
  [TransactionType.UNIBANK_WITHDRAW]: "unibankhaiti.com",

  // PayPal
  [TransactionType.PAYPAL]: "paypal.com",
  [TransactionType.PAYPAL_DEPOSIT]: "paypal.com",
  [TransactionType.PAYPAL_WITHDRAW]: "paypal.com",
  [TransactionType.PAYPAL_PAYOUT]: "paypal.com",

  // Sogebank
  [TransactionType.SOGEBANK_DEPOSIT]: "sogebank.com",
  [TransactionType.SOGEBANK_WITHDRAW]: "sogebank.com",

  // Bank Reservas
  [TransactionType.BANK_RESERVAS_DEPOSIT]: "banreservas.com",
  [TransactionType.BANK_RESERVAS_WITHDRAW]: "banreservas.com",
  [TransactionType.BANK_BHD_LEON_DEPOSIT]: "bhd.com.do",
  [TransactionType.BANK_BHD_LEON_WITHDRAW]: "bhd.com.do",

  // BUH
  [TransactionType.BUH_DEPOSIT]: "buh.ht",
  [TransactionType.BUH_WITHDRAW]: "buh.ht",

  // Crypto
  [TransactionType.BINANCE_DEPOSIT]: "binance.com",
  [TransactionType.BINANCE_WITHDRAW]: "binance.com",
  [TransactionType.CRYPTO_DEPOSIT]: "binance.com",
  [TransactionType.BITCOIN_DEPOSIT]: "binance.com",

  // Flutterwave
  [TransactionType.FLUTTERWAVE_DEPOSIT]: "flutterwave.com",

  // Stripe
  [TransactionType.STRIPE_DEPOSIT]: "stripe.com",
  [TransactionType.STRIPE]: "stripe.com",

  // Master Card
  [TransactionType.MASTERCARD_DEPOSIT]: "mastercard.com",
  [TransactionType.MASTERCARD_DEPOSIT_CREDIT]: "mastercard.com",
  [TransactionType.SUDO_MASTERCARD_WITHDRAW]: "mastercard.com",

  // CashApp
  [TransactionType.CASHAPP_DEPOSIT]: "cash.app",
  [TransactionType.CASHAPP_WITHDRAW]: "cash.app",

  // MonCash
  [TransactionType.MONCASH]: "moncashbutton.digicelgroup.com",
  [TransactionType.MONCASH_DEPOSIT]: "moncashbutton.digicelgroup.com",
  [TransactionType.MONCASH_DEPOSIT_VIA_API]: "moncashbutton.digicelgroup.com",
  [TransactionType.MONCASH_WITHDRAW]: "moncashbutton.digicelgroup.com",

  // NatCash
  [TransactionType.NATCASH]: "natcom.com.ht",
  [TransactionType.NATCASH_DEPOSIT]: "natcom.com.ht",
  [TransactionType.NATCASH_WITHDRAW]: "natcom.com.ht",

  // Western Union
  [TransactionType.WESTERN_UNION]: "westernunion.com",

  // Coinbase
  [TransactionType.COINBASE_DEPOSIT]: "coinbase.com",

  // Amazon
  [TransactionType.AMAZON_PURCHASE]: "amazon.com",

  // Shein
  [TransactionType.SHEIN_PURCHASE]: "shein.com",

  // Uber
  [TransactionType.UBER_RIDE]: "uber.com",
  [TransactionType.UBER_EATS_PURCHASE]: "ubereats.com",

  // Alibaba
  [TransactionType.ALIBABA_PURCHASE]: "alibaba.com",

  // Temu
  [TransactionType.TEMU_PURCHASE]: "temu.com",

  // AliExpress
  [TransactionType.ALIEXPRESS_PURCHASE]: "aliexpress.com",

  // Domain
  [TransactionType.DOMAIN_PURCHASE]: "domain.com",

  // Checkout
  [TransactionType.CHECKOUT]: "checkout.com",
  [TransactionType.PHYSICALCARD_COMMISSION]: "pgecom.com",
  [TransactionType.GIFT_CARD_COMMISION]: "pgecom.com",
  [TransactionType.BANK_DEPOSIT]: "pgecom.com",
  [TransactionType.CARD_BALANCE_RECHARGE]: "pgecom.com",
  [TransactionType.MANUAL_BALANCE_RECHARGE_INCREASE]: "pgecom.com",
  [TransactionType.GIFT_CARD_DEPOSIT]: "giftcard.com",
  [TransactionType.CARD_TRANSACTION]: "cardtransaction.com",
  [TransactionType.CARD_APPROVAL]: "cardapproval.com",
  [TransactionType.SALES]: "sales.com",
};
