export enum BalanceType {
  PENDING = "pending",
  BALANCE = "balance",
  GOURDES = "gourdes",
  CREDIT = "credit",
}

export enum BalanceFeeType {
  CHARGED = "charged",
  FREE = "free",
}

export interface IBalance {
  id?: string;
  amount?: number;
  credit?: number;
  pendingAmount?: number;
  totalAmountSpent?: number;
  userID?: string;
  gourdes?: string;
  lastDepositTime?: string;
  dailySpendingAmount?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface ITotalUserAsset {
  id?: string;
  totalAmount?: number;
  totalNegativeAmount?: number;
  totalCredit?: number;
  totalPendingAmount?: number;
  totalSpent?: number;
  totalGourdes?: number;
  topSpenders?: { userID: string; spent: number }[];
  topDebtors?: { userID: string; debt: number }[];
  createdAt?: string;
  updatedAt?: string;
}
